import * as React from 'react';
import * as styles from './css/style.module.scss';
import {Header} from "../Header";
import {FooterLink} from "../FooterLink";
import {SpTitle} from "../SpTitle";
import dayjs from "dayjs";

export const KouKokuWrap = (props) => {
    const {pageData} = props;
    return (<div className={styles.outer}>
        <Header spMenuDisplay={false} spMenuDisplayFunc={() => false} noMenu/>
        <div className={styles.wrap}>
            <div className={styles.SpTitle}><SpTitle titleText={'公告'} iconColor={['#7D469800', '#A87BC100', '#D2B0EA00']}
                                                     clipPathId={'sectionTitle'}/></div>
            <div className={styles.title}>
                公告
            </div>
            <div>
                {pageData.map((d, index) => <div className={styles.list__row} key={`${d.date}_${index}`}>
                    <div className={styles.list__date}>{dayjs(d.date).format('YYYY.MM.DD')}</div>
                    <div className={styles.list__title}>{d.title}</div>
                    <div className={styles.list__text} dangerouslySetInnerHTML={{
                        __html: d.body.map(d => {
                            if (d.link !== null) {
                                return `<a href="${d.link.url}" target="_blank" rel="noopener noreferrer">${d.content}</a>`;
                            }
                            return d.content
                        }).join('')
                    }}/>
                </div>)}
            </div>
        </div>
        <FooterLink />
    </div>);
}
