import * as React from "react"
import "normalize.css"
import {graphql} from "gatsby"
import {Head} from "../components/Head";
import {KouKokuWrap} from "../components/KouKokuWrap";


const KoukokuPage = ({data}) => {
    return <div>
        <Head data={data} pageData={{}} noindex/>
        <KouKokuWrap pageData={data.koukoku.data.sort((a, b) => new Date(b.date) - new Date(a.date))}/>
    </div>
}

// markup


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }  
        koukoku {
            data {
                title
                date
                body {
                    content
                    link {
                        url
                    }
                }
            }
        }
  
    }
`

export default KoukokuPage
